import React, { useState } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';
import ProjectThumbnail from '../components/Sections/Projects/ProjectThumbnail';
import { MetatagType, ProjectType } from '../types';
import MediaImage from '../components/Elements/MediaImage';

import '../fragments/metatags/pageMetatag';
import '../fragments/node/projectTeaser';

const { arrayOf, shape, object, string } = PropTypes;

const Projects = ({ data, location }) => {
  const [activeProject, setActiveProject] = useState(null);

  const onProjectHover = (project) => setActiveProject(project.id);

  const projectIsActive = (project, index) => {
    // If there is no active project, use the first one by default.
    if (activeProject) {
      return project.id === activeProject;
    } else {
      return index === 0;
    }
  };

  return (
    <Layout className="proyects" location={location}>
      <SEO metatags={data.page?.metatags} />
      <section className="section">
        <div className="container is-fluid">
          <div className="columns">
            <div className="is-flex column is-one-third menu-column">
              <div className="title-secction"><h1>Our Work</h1></div>
              <div className="proyects-list">
                <div className="proyects-list-inner">
                  {data.projects.edges && data.projects.edges.map(({ node }, index) => {
                    return (
                      <div className={`proyect-item ${(index % 2 === 0) ? 'odd ':'' }${projectIsActive(node, index) ? 'active' : ''}`} key={index}>
                        <ProjectThumbnail project={node} onMouseEnter={onProjectHover} />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="column img-column">
              <ul className="proyects-list-img">
                {data.projects.edges && data.projects.edges.map(({ node }, index) => {
                  return (
                    <li className={`proyect-list-img ${projectIsActive(node, index) ? 'active' : ''}`} key={index}>
                      <div className="img-bg">
                        {node.relationships?.previewImage?.relationships?.image && <MediaImage media={node.relationships.previewImage.relationships.image} alt={node.relationships.previewImage.media?.alt} title={node.relationships.previewImage.media?.title}/>}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Projects;

Projects.propTypes = {
  data: shape({
    page: shape({
      title: string.isRequired,
      metatag: arrayOf(MetatagType),
      relationships: PropTypes.shape({
        sections: PropTypes.array
      })
    }),
    projects: shape({
      edges: arrayOf(shape({
        node: ProjectType
      }))
    })
  }),
  pageContext: object,
  location: object
};

export const projectsQuery = graphql`
  query projectsQuery($skip: Int!, $limit: Int!) {
    page: nodePage(path: {alias: {eq: "/projects"}}) {
      title
      metatags: metatag_normalized {
        ...PageMetatag
      }
    }
    projects: allNodeProject(
      sort: { fields: changed, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...NodeProjectTeaser
        }
      }
    }
  }
`;
