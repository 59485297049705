import React from 'react';
import { func, shape, string } from 'prop-types';
import { Link } from 'gatsby';
import { MediaImageType } from '../../../types';
import MediaImage from '../../Elements/MediaImage';



const ProjectThumbnail = ({ project, onMouseEnter }) => {
  return (
      <Link to={project.path.alias} onMouseEnter={() => onMouseEnter && onMouseEnter(project)}>
        <div className="item-content">
          <h2>{project.title}</h2>
          <h3>{project.body?.summary}</h3>
        </div>
        <div className="item-img">
          <MediaImage media={project.relationships.previewImage.relationships.image} alt={project.relationships.previewImage.media?.alt} title={project.relationships.previewImage.media?.title}/>
        </div>
      </Link>
  );
};

ProjectThumbnail.propTypes = {
  project: shape({
    title: string,
    body: shape({
      summary: string
    }),
    path: shape({
      alias: string
    }),
    relationships: shape({
      previewImage: MediaImageType
    })
  }),
  onMouseEnter: func
};

export default ProjectThumbnail;
